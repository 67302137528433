// import React, { useEffect, useState } from "react";
// import { Route, Redirect, useHistory, useLocation } from "react-router-dom";

// import Box from "@mui/material/Box";
// import { styled } from "@mui/material/styles";

// import { useAuth } from "../context/authContext";
// import { fetchToken } from "../context/auth";
// import Navbar from "../Components/Navbar";
// import callAPI from "../utils/callApi";

// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "flex-end",
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
// }));

// export default function ProtectedRoute({ component: Component, ...rest }) {
//   const isAuthenticated = fetchToken();
//   const auth = useAuth();
//   const history = useHistory();
//   const location = useLocation();
//   const loggedInUser = localStorage.getItem("user");
//   const [open, setOpen] = useState(false);
//   const { from } = location.state || { from: { pathname: "/" } };
//   const [shouldDashboardsReload, setShouldDashboardsReload] = useState(true);

//   return (
//     <>
//       <Navbar
//         open={open}
//         setOpen={setOpen}
//         shouldDashboardsReload={shouldDashboardsReload}
//         setShouldDashboardsReload={setShouldDashboardsReload}
//       />
//       <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
//         <DrawerHeader />

//         <Route
//           {...rest}
//           render={(props) =>
//             isAuthenticated ? (
//               <Component
//                 {...props}
//                 sidebarOPen={open}
//                 shouldDashboardsReload={shouldDashboardsReload}
//                 setShouldDashboardsReload={setShouldDashboardsReload}
//               />
//             ) : (
//               <Redirect
//                 to={{ pathname: "/login", state: { from: props.location } }}
//               />
//             )
//           }
//         />
//       </Box>
//     </>
//   );
// }
//        if (!authentication) {
//          localStorage.removeItem("user");
//          localStorage.removeItem("jwtToken");
//          history.push("/login");
//        } else {
//          const userName = loggedInUser;
//          if (loggedInUser) {
//            auth.signin(200, userName);
//             history.push(from.pathname);
//          } else {
//            auth.signin(400, userName);
//          }
//        }
//        <Route
//          // eslint-disable-next-line react/jsx-props-no-spreading
//          {...rest}
//          render={({ match, location }) =>
//            loggedInUser === (undefined || null) ? (
//              <main>
//                <Component
//                  match={match}
//                  sidebarOPen={open}
//                  shouldDashboardsReload={shouldDashboardsReload}
//                  setShouldDashboardsReload={setShouldDashboardsReload}
//                />
//              </main>
//            ) : (
//              <Redirect
//                to={{
//                  pathname: "/login",
//                  state: { from: location },
//                }}
//              />
//            )
//          }
//        />
import React, { useState } from "react";
import { Route } from "react-router-dom";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Navbar from "../Components/Navbar";
import Footer from "../routes/Footer"; // Import the Footer component
import { useCentralContext } from "../context/centralContext";
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export default function SimpleRoute({ component: Component, ...rest }) {
  const { open ,shouldDashboardsReload, setShouldDashboardsReload} = useCentralContext(); 
  

  return (
    <>
      <Navbar
        
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Route
          {...rest}
          render={(props) => (
            <Component
              {...props}
              // sidebarOpen={open}
              // shouldDashboardsReload={shouldDashboardsReload}
              // setShouldDashboardsReload={setShouldDashboardsReload}
            />
          )}
        />
      </Box>
      <Footer /> {/* Add the Footer component here */}
    </>
  );
}
