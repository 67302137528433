import React, { useContext, createContext, useState } from "react";


const CentralContext = createContext();

/**
 * The function returns the authentication context.
 * @returns The `useAuth` function is returning the value of the `authContext` that is being accessed
 * through the `useContext` hook.
 */
export function useCentralContext() {
  return useContext(CentralContext);
}

function useCentralContextProvider() {
    const [open, setOpen] = useState(false);
  const [shouldDashboardsReload, setShouldDashboardsReload] = useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
      };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  return {
    open,
    setOpen,
    shouldDashboardsReload,
    setShouldDashboardsReload,
    handleDrawerOpen,handleDrawerClose
  };
}

/**
 * The AuthProvider function is a React component that wraps its children with an authentication
 * context provider.
 * @returns The `AuthProvider` component is returning a `authContext.Provider` component with the
 * `auth` value provided by the `useAuthProvider` hook as its value prop. The `children` prop is also
 * being rendered as the children of the `authContext.Provider` component.
 */
export function CentralContextProvider({ children }) {
  const data = useCentralContextProvider();
  return <CentralContext.Provider value={data}>{children}</CentralContext.Provider>;
}
