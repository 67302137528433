import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import { Dashboard, FormatListBulleted } from "@mui/icons-material";
import BiotechIcon from "@mui/icons-material/Biotech";
import image from "../assets/RIID.jpg";
import { useCentralContext } from "../context/centralContext";
const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  minHeight: "10px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar() {
  const location = useLocation();
  const pathName = location.pathname;
  const { open, handleDrawerClose} = useCentralContext(); 
  return (
    <Drawer variant="permanent" open={open} onClose={handleDrawerClose} >
      <div
        className="d-flex link m-0 mb-5 d-flex align-items-center justify-content-center"
        style={{
          maxHeight: "64px",
          height: "64px"
        }}
      >
        {open ? (
          <>
            {/* <span className="text-lg">Active Listening</span> */}
            <DrawerHeader style={{ minHeight: "100px" }}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon sx={{bgcolor:"white"}}/>
              </IconButton>
            </DrawerHeader>
          </>
        ) : null}
      </div>
      {open ? (
        <>
          {/* <p className="uppercase ms-4 mt-[-3rem]">Main Menu</p> */}
          <aside id="logo-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
       <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-white">
          <div className="flex items-center ps-2.5 mb-5">
          <img src={image} className="h-6 me-3 sm:h-7" alt="Priid Logo" />
          
             <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-[#da2027]">Priid</span> 
             
             <DrawerHeader style={{ minHeight: "100px",marginLeft:"5.5rem" }}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon fontSize="large"/>
              </IconButton>
            </DrawerHeader>
            
          </div>
          <List className="p-6 pr-3 mr-10" style={{marginTop: "-2rem"}}>
            <NavLink to="/start" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-[#da2027] hover:bg-priid-900 dark:hover:bg-[#da2027] group">
              <ListItem button className="space-y-2 font-medium">
                <BiotechIcon className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" >
                  <FormatListBulleted
                    sx={{ fontSize: "15px" }}
                    style={{
                      color: pathName === "/start" ? "#997850" : "",
                    }}
                  />
                </BiotechIcon>

                <ListItemText
                className="ms-3"
                
                  disableTypography
                  primary={
                    <Typography
                      variant="body2"
                      className="h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                      style={{
                        color: pathName === "/start" ? "#997850" : "",
                        fontSize: "1rem",
                      }}
                    >
                      PRIID Smear Dash
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>
            <NavLink to="/report"className="flex items-center p-2 text-gray-900 rounded-lg dark:text-black hover:bg-priid-900 dark:hover:bg-[#da2027] group">
              <ListItem button className="space-y-2 font-medium">
                <AnalyticsIcon className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                  <Dashboard
                    sx={{ fontSize: "15px" }}
                    color={pathName === "/report" ? "#997850" : ""}
                  />
                </AnalyticsIcon>

                <ListItemText
                className="ms-3"
                  disableTypography
                  primary={
                    <Typography
                      variant="body2"
                      className="h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                      color={pathName === "/report" ? "#997850" : ""}
                      style={{ fontSize: "15px" }}
                    >
                      Report
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>
          </List>
          </div>
    </aside>
        </>
      ) : (
        <List>
          <NavLink to="/start">
            <ListItem className="d-flex justify-content-center mb-2" button>
              <BiotechIcon fontSize="large" style={{ color: "#455964" }}>
                <FormatListBulleted
                  style={{
                    color: pathName === "/start" ? "#997850" : "",
                  }}
                />
              </BiotechIcon>
            </ListItem>
          </NavLink>
          <NavLink to="/report">
            <ListItem className="d-flex justify-content-center mb-2" button>
              <AnalyticsIcon fontSize="large" style={{ color: "#455964" }}>
                <Dashboard color={pathName === "/report" ? "#997850" : ""} />
              </AnalyticsIcon>
            </ListItem>
          </NavLink>
        </List>
      )}
    </Drawer>
    
  );
}
