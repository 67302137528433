// import React, { useState } from "react";
// import { useHistory } from "react-router-dom";

// import { styled } from "@mui/material/styles";
// import MuiAppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import CssBaseline from "@mui/material/CssBaseline";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
// import { Menu, MenuItem } from "@mui/material";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import Sidebar from "./Sidebar";
// import callAPI from "../utils/callApi";
// import { useAuth } from "../context/authContext";
// import { HOMECOLOR } from "../utils/constants";
// import RIID from "../Components/assets/navbar.svg";
// const drawerWidth = 240;
// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(["width", "margin"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

// export default function Navbar({
//   open,
//   setOpen,
//   shouldDashboardsReload,
//   setShouldDashboardsReload,
// }) {
//   const history = useHistory();
//   const auth = useAuth();
//   // const [open, setOpen] = useState(false);

//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

//   const [anchorEl, setAnchorEl] = useState(null);
//   /* The `handleMouseLeave` function is used to handle the event when the mouse leaves the menu
//   button. It sets the `anchorEl` state to `null`, which closes the menu. */
//   const handleMouseLeave = (event) => {
//     setAnchorEl(null);
//   };
//   /**
//    * The function `handleMenuClick` toggles the `anchorEl` state between `null` and the target element
//    * of the event.
//    * @param event - The `event` parameter is an object that represents the event that triggered the
//    * function. It contains information about the event, such as the target element that was clicked.
//    */
//   const handleMenuClick = (event) => {
//     if (anchorEl) {
//       setAnchorEl(null);
//     } else {
//       const target = event.currentTarget;
//       setAnchorEl(target);
//     }
//   };
//   /**
//    * The `handleClose` function logs out the user by making a GET request to the "logout/" endpoint,
//    * clearing the localStorage, deleting all cookies, and redirecting to the login page.
//    */
//   const handleClose = () => {
//     //    callAPI("GET", "logout/", "")
//     //      .then(() => {
//     //        localStorage.clear();
//     //        document.cookie.split(";").forEach((cookie) => {
//     //          document.cookie = cookie
//     //            .replace(/^ +/, "")
//     //            .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
//     //        });
//     //      })
//     //      .then(() => {
//     //        history.push("/login");
//     //      });
//     localStorage.removeItem("jwtToken");
//     document.cookie.split(";").forEach((cookie) => {
//       document.cookie = cookie
//         .replace(/^ +/, "")
//         .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
//     });
//     history.push("/login");
//   };

//   return (
//     <>
//       <CssBaseline />
//       <AppBar
//         position="fixed"
//         open={open}
//         sx={{ backgroundColor: HOMECOLOR }}
//         style={{ boxShadow: "none" }}
//       >
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             onClick={handleDrawerOpen}
//             edge="start"
//             sx={{
//               marginRight: "36px",
//               ...(open && { display: "none" }),
//               color: "#FFFFFF",
//             }}
//           >
//             <MenuIcon />
//           </IconButton>

//           <div className=" w-100 ">
//             <div className="row ">
              
//               <div className="flex flex-row justify-center relative">
                
//                 {open ? (
//                   ""
//                 ) : (
//                   <img
//                     src={RIID}
//                     // className="mt-[-57%] h-[22%]"
//                     className="max-w-16 ml-[-1rem]"
//                     alt="No Logo Found"
//                   />
//                 )}
//               </div>
//             </div>
//           </div>
//         </Toolbar>
//       </AppBar>
//       <Sidebar
//         open={open}
//         handleDrawerClose={handleDrawerClose}
//         handleDrawerOpen={handleDrawerOpen}
//         shouldDashboardsReload={shouldDashboardsReload}
//         setShouldDashboardsReload={setShouldDashboardsReload}
//       />
//     </>
//   );
// }
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import { useAuth } from "../context/authContext";
import { HOMECOLOR } from "../utils/constants";
import RIID from "../Components/assets/navbar.svg";
import { color } from "chart.js/helpers";
import { useCentralContext } from "../context/centralContext";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Navbar() {
  const { open, handleDrawerOpen} = useCentralContext(); 
  const history = useHistory();
  const auth = useAuth();

 
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      const target = event.currentTarget;
      setAnchorEl(target);
    }
  };

  const handleClose = () => {
    localStorage.removeItem("jwtToken");
    document.cookie.split(";").forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    history.push("/login");
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: 'transparent' }}
        style={{ boxShadow: "none" }}
        
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
              color: "#FFFFFF",
            }}
          >
            <MenuIcon sx={{color:"#DA2027",fontSize:"2rem"}}/>
          </IconButton>
          {!open && (
            <img
              src={RIID}
              className="max-w-16"
              alt="No Logo Found"
              style={{ marginRight: "1rem" }}
            />
          )}
        </Toolbar>
      </AppBar>
      <Sidebar
        // open={open}
        // handleDrawerClose={handleDrawerClose}
        // handleDrawerOpen={handleDrawerOpen}
        // shouldDashboardsReload={shouldDashboardsReload}
        // setShouldDashboardsReload={setShouldDashboardsReload}
      />
    </>
  );
}
